import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UnifiedFlowService } from '../unified.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';

export interface NoteData {
  profile: string;
  bridgeId: string;
  note: string;
  prompt: string;
  app: string;
  required: boolean;
}

@Component({
  selector: 'app-notes-entry',
  templateUrl: './notes-entry.component.html',
  styleUrls: ['./notes-entry.component.scss'],
})
export class NotesEntryComponent implements OnInit {
  @Input() notesData: NoteData = {} as NoteData;
  @Input() bridgeVal;
  @Input() moduleNotes = false;
  @Input() moduleBridges;
  @Output() noteEntered = new EventEmitter();

  noteEntry = new Subject<string>();
  note;
  loading = false;
  noteSaved = false;
  fieldTouched = false;
  noteScore = '';
  scoreMessage = '';
  rights = this.ss.get('rights');
  globals = this.ss.get('globals');
  maxSubmissionCount = 20;
  submissionCount = 0;
  containsPii = false;
  moduleNoteSaved = false;

  constructor(
    private unfFlowSvc: UnifiedFlowService,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService
  ) {}

  ngOnInit(): void {
    this.maxSubmissionCount =
      this.globals?.GlobalsGroup?.UnitAISettings?.MaxSubmissions || 1;
    this.note = this.notesData.note;

    if (this.note && this.moduleNotes) {
      this.moduleNoteSaved = true;
    }

    this.noteEntry
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(() => {
        this.fieldTouched = true;
      });
  }

  saveNote(validate) {
    this.containsPii = this.checkForPii(this.note);

    if (!this.containsPii) {
      if (validate) {
        this.loading = true;
      }

      const valid = this.notesData.required ? this.note.length > 0 : true;
      let saveNotePayload;
      this.noteEntered.emit(valid);

      if (this.notesData.app === 'pp') {
        saveNotePayload = { ClientProfiling: { Notes: this.note } };
      }

      if (this.notesData.app === 'aw') {
        saveNotePayload = { AnnuityWizard: { Note: this.note } };
      }

      if (this.notesData.app === 'iw') {
        saveNotePayload = { InvestmentWizard: { ValidationNote: this.note } };
      }

      if (this.notesData.app === 'li') {
        saveNotePayload = { LifeWizard: { Note: this.note } };
      }

      if (this.moduleNotes) {
        this.rbs
          .saveProfile(this.notesData.profile, saveNotePayload, false, false)
          .subscribe(() => {
            this.moduleNoteSaved = true;
            this.noteSaved = true;
            this.fieldTouched = false;
            const hasShowNoteAssistant =
              this.rights.includes('ShowNoteAssistant');
            const hasNoteAssistantOnRequired = this.rights.includes(
              'NoteAssistantOnRequired'
            );
            if (
              !hasShowNoteAssistant &&
              !hasNoteAssistantOnRequired &&
              this.notesData.required
            ) {
              this.loading = false;
            }
            setTimeout(() => {
              this.noteSaved = false;
            }, 3000);
          });
      } else {
        this.unfFlowSvc
          .saveNote(
            this.notesData.profile,
            this.note,
            this.notesData.bridgeId,
            this.notesData.app
          )
          .subscribe(x => {
            if (!validate || !this.notesData.required) {
              this.loading = false;
            }

            if (x.result === 'Success') {
              this.noteSaved = true;
              this.fieldTouched = false;
              const hasShowNoteAssistant =
                this.rights.includes('ShowNoteAssistant');
              const hasNoteAssistantOnRequired = this.rights.includes(
                'NoteAssistantOnRequired'
              );
              if (
                !hasShowNoteAssistant &&
                !hasNoteAssistantOnRequired &&
                this.notesData.required
              ) {
                this.loading = false;
              }
              setTimeout(() => {
                this.noteSaved = false;
              }, 3000);
            }
          });
      }
    }
  }

  validateAndSaveNote() {
    this.containsPii = this.checkForPii(this.note);

    if (!this.containsPii) {
      const hasShowNoteAssistant = this.rights.includes('ShowNoteAssistant');
      const hasNoteAssistantOnRequired = this.rights.includes(
        'NoteAssistantOnRequired'
      );

      this.saveNote(hasShowNoteAssistant);

      if (this.moduleNotes) {
        if (this.submissionCount < this.maxSubmissionCount) {
          this.unfFlowSvc
            .validateNote(
              this.collectModuleBridges(),
              this.note,
              this.notesData.profile
            )
            .subscribe(
              resp => {
                this.loading = false;
                this.noteScore = resp['NoteScore'];
                this.submissionCount = this.submissionCount + 1;
                this.scoreMessage = resp['NoteUserResponse'];

                // COMMENTING THIS CODE BECAUSE IT WAS PROBABLY INCORRECTLY PRESCRIBED, BUT LEAVING HERE AS A COMMENT JUST IN CASE
                // if (resp['NoteScore'] === 'negative') {
                //   this.unfFlowSvc.setNoteValidity({
                //     app: this.notesData.app,
                //     valid: false,
                //   });
                // }

                this.loading = false;
              },
              err => {
                this.loading = false;
                console.error('ERROR: ', err);
              }
            );
        } else if (this.submissionCount >= this.maxSubmissionCount) {
          this.noteScore = 'positive';
          this.loading = false;
        }
      } else {
        const bridgeData = this.cleanReasonText(this.bridgeVal);
        if (
          ((hasNoteAssistantOnRequired && this.notesData.required) ||
            !hasNoteAssistantOnRequired) &&
          this.submissionCount < this.maxSubmissionCount
        ) {
          this.loading = true;
          this.unfFlowSvc
            .validateNote(bridgeData, this.note, this.notesData.profile)
            .subscribe(
              resp => {
                this.loading = false;
                this.noteScore = resp['NoteScore'];
                this.submissionCount = this.submissionCount + 1;
                this.scoreMessage = resp['NoteUserResponse'];

                // if (resp['NoteScore'] === 'negative') {
                //   this.unfFlowSvc.setNoteValidity({
                //     app: this.notesData.app,
                //     valid: false,
                //   });
                // }

                this.loading = false;
              },
              err => {
                this.loading = false;
                console.error('ERROR: ', err);
              }
            );
        } else if (
          hasNoteAssistantOnRequired &&
          this.notesData.required &&
          this.submissionCount >= this.maxSubmissionCount
        ) {
          this.noteScore = 'positive';
          this.loading = false;
        }
      }
    }
  }

  checkForPii(text) {
    this.containsPii = false;
    const piiReg = new RegExp(
      /((?<!\$|\.)\b(\b\w{2,10}\d{3,10}\b))|(\d{3}[- ]?\d{2}[- ]?\d{4}\b)/
    );

    return piiReg.test(text);
  }

  collectModuleBridges() {
    const bridgePayload = [];

    this.moduleBridges.map(bridge => {
      if (
        (this.notesData.app === 'pp' &&
          (bridge.BridgeType === 'rollover' ||
            (bridge.BridgeType === 'product' &&
              bridge.assessmenttype === 'validate'))) ||
        (this.notesData.app === 'aw' &&
          (bridge.SelectedBridge === 'Yes' ||
            bridge.assessmenttype === 'exchange')) ||
        (this.notesData.app === 'iw' &&
          (bridge.SelectedBridge === 'Yes' ||
            bridge.BridgeType === 'program' ||
            bridge.BridgeType === 'portfolio')) ||
        (this.notesData.app === 'li' && bridge.SelectedBridge === true)
      ) {
        bridge.trueRules.map(trueRule => {
          if (trueRule.reasonType === 'Negative') {
            bridgePayload.push(trueRule);
          }
        });
      }
    });

    return bridgePayload;
  }

  closeValidation() {
    this.noteScore = '';
    this.scoreMessage = '';
  }

  closePii() {
    this.containsPii = false;
  }

  cleanReasonText(reasons) {
    const resp = [];

    reasons.forEach(el => {
      const reason = el;
      delete reason.fired;
      delete reason.name;
      delete reason.reason;
      delete reason.score;

      reason.explanation = this.cleanHtml(reason.explanation);
      reason.headline = this.cleanHtml(reason.headline);

      resp.push(reason);
    });

    const cleanedList = resp.filter(item => item.reasonType === 'Negative');

    return cleanedList;
  }

  cleanHtml(value) {
    const htmlRegEx = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    return value.replace(htmlRegEx, '');
  }
}
