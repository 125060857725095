import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalsService } from '../services/globals.service';
import { EnvironmentService } from '../services/environment.service';
import jwt_decode from 'jwt-decode';
import { ErrorReportingService } from './error-reporting.service';

@Injectable()
export class RightBridgeApiService {
  private environment;
  private sessionId: string = this.ss.get('session');
  private token: string = this.ss.get('token');
  public baseUrl: string;
  public f5500BaseUrl: string;
  private firm: string;
  private app: string;
  private org: string;
  private key: any = 'MetallicSwamp';
  private tabulaRecta = {
    a: 'abcdefghijklmnopqrstuvwxyz',
    b: 'bcdefghijklmnopqrstuvwxyza',
    c: 'cdefghijklmnopqrstuvwxyzab',
    d: 'defghijklmnopqrstuvwxyzabc',
    e: 'efghijklmnopqrstuvwxyzabcd',
    f: 'fghijklmnopqrstuvwxyzabcde',
    g: 'ghijklmnopqrstuvwxyzabcdef',
    h: 'hijklmnopqrstuvwxyzabcdefg',
    i: 'ijklmnopqrstuvwxyzabcdefgh',
    j: 'jklmnopqrstuvwxyzabcdefghi',
    k: 'klmnopqrstuvwxyzabcdefghij',
    l: 'lmnopqrstuvwxyzabcdefghijk',
    m: 'mnopqrstuvwxyzabcdefghijkl',
    n: 'nopqrstuvwxyzabcdefghijklm',
    o: 'opqrstuvwxyzabcdefghijklmn',
    p: 'pqrstuvwxyzabcdefghijklmno',
    q: 'qrstuvwxyzabcdefghijklmnop',
    r: 'rstuvwxyzabcdefghijklmnopq',
    s: 'stuvwxyzabcdefghijklmnopqr',
    t: 'tuvwxyzabcdefghijklmnopqrs',
    u: 'uvwxyzabcdefghijklmnopqrst',
    v: 'vwxyzabcdefghijklmnopqrstu',
    w: 'wxyzabcdefghijklmnopqrstuv',
    x: 'xyzabcdefghijklmnopqrstuvw',
    y: 'yzabcdefghijklmnopqrstuvwx',
    z: 'zabcdefghijklmnopqrstuvwxy',
  };

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private router: Router,
    private dialogRef: MatDialog,
    private globalsService: GlobalsService,
    private envSvc: EnvironmentService,
    private zone: NgZone,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.f5500BaseUrl = this.environment.f5500Base;
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'rb';
    this.org = this.environment.org || 'basev2';
  }

  login(data): Observable<any> {
    this.ss.clear();

    if (!this.baseUrl) {
      this.envSvc.loadSettings();
    }

    const reqHeaders = new HttpHeaders()
      .set('X-RB-U', data.name)
      .set('X-RB-P', data.pass)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Session/start';

    let result;
    let results;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        results = res;
        result = results.result;
        this.ss.set('session', result);
        const startPage = results.startPage
          ? results.startPage.toLowerCase()
          : null;
        this.app = startPage === 'unified' ? 'rb' : startPage;

        this.ss.set('startPage', startPage);
        this.ss.set('currentApp', startPage);

        return results;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error, false);
      })
    );
  }

  getToken(): Observable<any> {
    if (this.token == null || this.isTokenExpired()) {
      const reqHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-RB-O', this.getOrg())
        .set('X-RB-I', this.getApp());

      const url = this.baseUrl + '/Token';
      return this.http
        .post(url, `{"subjectToken":"${this.getSession()}"}`, {
          headers: reqHeaders,
        })
        .pipe(
          map(res => {
            this.token = res['access_token'];
            this.ss.set('token', this.token);
            return res;
          })
        );
    } else {
      return of({ access_token: this.token });
    }
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime(this.token);
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }
  getExpiryTime(token) {
    const decodedToken = this.decodeToken(token);
    return decodedToken ? decodedToken.exp : null;
  }

  decodeToken(jwtToken: string): any {
    if (jwtToken) {
      return jwt_decode(jwtToken);
    }
  }

  getRlcPdf(filename: string): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Reports/RLC?filename=${filename}`;

    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getFactsheet(filename: string): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Reports/F5500FactSheet?planID=${filename}`;

    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  setParams(
    app: string = this.app,
    org: string = this.org,
    firm: string = this.firm
  ) {
    this.ss.set('currentApp', app);
    this.firm = firm || this.setFirmId();
    this.app = app ? app.toLowerCase() : this.ss.get('currentApp');

    this.org = org;
  }

  setRogue(app = 'aw', org: string = this.org, session: string) {
    this.ss.set('session', session);
    this.getGlobals().subscribe(data => {
      this.ss.set('rights', data.results.user.rights);
    });
    this.org = org;
    this.ss.set('hideMenu', true);
    this.firm = org;
  }

  setSso(
    org: string = this.org,
    session: string,
    app?: string,
    profile?: string,
    search?: string,
    bridgeId?: string
  ) {
    let rights = [];
    this.ss.set('session', session);
    this.getGlobals().subscribe(data => {
      this.ss.set('rights', data.results.user.rights);
      rights = data.results.user.rights;

      this.org = org;
      this.firm = this.setFirmId(data.results);

      const computedApp = app ? app : this.environment.defaultApp;
      const dashboard = rights.includes('NewDashboardTab');
      const appPage =
        computedApp &&
        (computedApp.toLowerCase() == 'aw' ||
          computedApp.toLowerCase() == 'iw' ||
          computedApp.toLowerCase() == 'li' ||
          computedApp.toLowerCase() == 'pp' ||
          computedApp.toLowerCase() == 'rb' ||
          computedApp.toLowerCase() == 'se');

      if (appPage) {
        this.app = computedApp.toLowerCase();
        this.ss.set('currentApp', computedApp.toLowerCase());
      }

      if (computedApp.toLowerCase() == 'se') {
        if (profile && this.app == 'se') {
          this.router.navigate(['/' + this.app + '/client/' + profile]);
        } else if (search && bridgeId && this.app == 'se') {
          this.router.navigate([
            '/' + this.app + '/search/' + search + '/' + bridgeId,
          ]);
        } else if (search && this.app == 'se') {
          this.router.navigate(['/' + this.app + '/search/' + search]);
        } else if (profile) {
          this.router.navigate(['/' + this.app + '/questionnaire/' + profile]);
        } else if (dashboard) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/' + this.app]);
        }
      } else {
        if (profile) {
          if (
            this.ss.get('globals') &&
            this.ss.get('globals')['startPage'] === 'pp'
          ) {
            this.router.navigate([`case/rb/${profile}/0`]);
          } else {
            this.router.navigate([`case/${this.app}/${profile}/0`]);
          }
        } else if (appPage) {
          this.router.navigate([`/unified`]);
        } else if (!appPage) {
          switch (computedApp.toLowerCase()) {
            case 'ps':
              this.router.navigate([`/aw/product-shelf`]);
              break;
            case 'fiar':
              this.router.navigate([`/aw/fia`]);
              break;
            case 'rr':
              this.router.navigate([`/aw/rila`]);
              break;
            case 'far':
              this.router.navigate([`/aw/fa`]);
              break;
            case 'pl':
              this.router.navigate([`/aw/lookup`]);
              break;
            case 'rc':
              this.router.navigate([`/aw/rmd-calculation`]);
              break;
            case 'fc':
              this.router.navigate([`/iw/lookup`]);
              break;
            case 'tm':
              this.router.navigate([`/iw/templates`]);
              break;
            case 'dash':
              this.router.navigate([`/dashboard`]);
              break;
            case 'tools':
              this.router.navigate([`/tools`]);
              break;
            case 'ue':
              this.router.navigate([`/unified`]);
              break;
            default:
              this.router.navigate([`/unified`]);
              break;
          }
        } else if (dashboard) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/unified']);
        }
      }
    });
  }

  getSession(): string {
    return this.sessionId;
  }
  getApp(): string {
    return this.app;
  }
  getOrg(): string {
    return this.org;
  }
  getJwt(): string {
    return this.token;
  }
  clearJwt(): void {
    this.token = null;
    this.ss.set('token', this.token);
  }

  endSession(session?): Observable<any> {
    const sess = session || this.sessionId;

    const app = this.app || 'rb';

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Session/end';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        this.ss.clear();
        this.sessionId = null;
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  querySession(id?, org?, app?, sso = false): Observable<any> {
    this.sessionId = id || this.sessionId;
    this.org = org || this.org;
    this.app = app ? app.toLowerCase() : this.ss.get('currentApp') || this.app;

    const requestApp = this.app === 'se' ? 'se' : 'rb';

    if (id) {
      this.ss.set('session', id);
    }
    if (app) {
      this.ss.set('currentApp', app);
    }
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', requestApp)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Session/query';

    if (this.sessionId) {
      return this.http.get(url, { headers: reqHeaders }).pipe(
        map(res => {
          return res;
        }),
        catchError(() => {
          if (!sso) {
            this.logout();
          }
          return of({ results: 'invalid' });
        })
      );
    }
  }

  resetSession(id?): Observable<any> {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Session/reset';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  saveProfile(
    id,
    data,
    evaluate = false,
    returnProfile = false
  ): Observable<any> {
    const sessId = this.ss.get('session');
    let app;
    if (this.app == 'summary') {
      app = 'pp';
    } else if (
      this.app === 'pp' &&
      (this.ss.get('currentStep') === 0 || this.ss.get('currentStep') === '0')
    ) {
      app = 'rb';
    } else {
      app = this.app;
    }
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const profile = id;

    let url = this.baseUrl + '/Profile/' + profile;

    url += `?evaluateFunctionsOnly=${evaluate}&`;

    if (!evaluate) {
      url += '?';
    }

    if (returnProfile) {
      url += 'returnProfile=true';
    } else {
      url += 'returnProfile=false';
    }

    return this.http.patch(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  newProfile(data, evaluate = false): Observable<any> {
    const sessId = this.ss.get('session');
    const app = this.app == 'rb' ? 'pp' : this.app;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');

    let url = this.baseUrl + '/Profile';

    if (evaluate) {
      url += '?evaluateFunctionsOnly=true';
    }

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  getProfile(id?): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const profile = id;

    const url = this.baseUrl + '/Profile/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  copyProfile(id, desc): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const profile = id;

    const url =
      this.baseUrl + '/Profile/Copy/' + profile + '?profileDescription=' + desc;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  swapProfile(id, desc): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const profile = id;

    const url =
      this.baseUrl + '/Profile/Swap/' + profile + '?profileDescription=' + desc;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  deleteProfile(id): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const profile = id;

    const url = this.baseUrl + '/Profile/' + profile;

    return this.http.delete(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  getGlobals(session?, org?, app?): Observable<any> {
    let appName;

    if (
      this.ss.get('startPage') && this.ss.get('startPage').length > 0
        ? this.ss.get('startPage').toLowerCase() === 'se'
        : null
    ) {
      this.setApp('se');
    }

    if (this.app === 'se') {
      app = 'se';
    }

    if (
      !app ||
      (this.app.toLowerCase() !== 'pp' &&
        this.app.toLowerCase() !== 'aw' &&
        this.app.toLowerCase() !== 'iw' &&
        this.app.toLowerCase() !== 'li' &&
        this.app.toLowerCase() !== 'se' &&
        this.app.toLowerCase() !== 'rb')
    ) {
      this.setApp('rb');
    }

    if (app) {
      appName = app;
    } else {
      appName = this.app;
    }

    this.ss.remove('globals');
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', appName)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Globals';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        const data = res;
        this.ss.set('globals', data['results']);

        this.firm = this.setFirmId();
        this.ss.set('firm', this.firm);

        const rights = this.ss.get('globals').user.rights;
        this.ss.set('rights', rights);
        return data;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  getRepUnits(searchString?): Observable<any> {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', this.app || 'rb')
      .set('X-RB-O', this.org);

    let url = `${this.baseUrl}/Hierarchy/RepCode`;

    if (searchString) {
      url = url + `?searchString=${searchString}`;
    } else {
      url = url + 'List';
    }

    return this.getRequest(url, reqHeaders);
  }

  getRights(): Observable<any> {
    let rights = null;
    rights = this.ss.get('rights');
    if (!rights) {
      return observableThrowError('No rights yet');
    }
    return of(rights);
  }

  profileSearch(search): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Search/Profile?searchString=%25&numRows=999999';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  getQuestions(profile?, app?): Observable<any> {
    const id = this.ss.get('session');

    if (app) {
      this.setApp(app);
    }

    let reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    let url = profile
      ? this.baseUrl + '/Questionnaire/' + profile
      : this.baseUrl + '/Questionnaire';

    if (this.app == 'rb') {
      reqHeaders = new HttpHeaders()
        .set('X-RB-S', id)
        .set('X-RB-I', 'pp')
        .set('X-RB-O', this.org);
      url += '?type=universal-clientInfo';
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processModel(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Process/Model/' + profile;
    return this.http
      .get(url, { headers: reqHeaders, reportProgress: true })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  processCategories(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/Process/FundCategories/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processFunds(
    program,
    asset,
    profile,
    shareClass?,
    fundFams?,
    type?
  ): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.setFirmId(null, true) || this.firm;
    let url =
      this.baseUrl +
      '/Process/Funds/' +
      profile +
      '?programId=' +
      program +
      '&assetClass=' +
      asset +
      '&firmId=' +
      this.firm;

    if (shareClass) {
      url += '&shareClass=' + shareClass;
    }

    if (fundFams) {
      url += '&fundFamilyIds=' + fundFams;
    }

    if (type) {
      url += '&fundType=' + type;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processAnnuities(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Process/Annuities/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processProducts(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Process/Products/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processNeeds(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'cp')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Process/Needs/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  selectFunds(id, data, clear?): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const profile = id;
    const fundData = { Values: [] };
    if (!clear) {
      fundData.Values = data;
    } else {
      fundData.Values[0] = {
        'SelectedFunds.FundID': '',
        'SelectedFunds.Symbol': '',
        'SelectedFunds.AssetClass': '',
        'SelectedFunds.Name': '',
        'SelectedFunds.Amount': '',
        'SelectedFunds.FundFamilyID': '',
        'SelectedFunds.IsSpecial': '',
        'SelectedFunds.FundType': '',
      };
    }

    const url = this.baseUrl + '/Select/Funds/' + profile;

    return this.http.patch(url, fundData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  clearFunds(id): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const profile = id;

    const url = this.baseUrl + '/Profile/' + profile;
    const data = {
      'InvestmentWizard.IsFinalized': false,
      'InvestmentWizard.FilteredFundFamilies': null,
    };

    return this.http.patch(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  processPortfolio(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Process/Portfolio/' + profile;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  selectAnnuity(id, note, annuity, clearSelected): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const profile = id;

    const processedNote = note && note.length > 0 ? note : '';

    const axData = {
      'CurrentBridgeAction.ActiveFlag': 1,
      'CurrentBridgeAction.AcceptType': 2301,
      'CurrentBridgeAction.FWDateType': '',
      'CurrentBridgeAction.Fwdate': '',
      'CurrentBridgeAction.Note': processedNote,
      sysActions: 0,
      bridgeId: annuity,
      actionId: 2301,
    };

    let url = this.baseUrl + '/Select/Annuities/' + profile;

    if (clearSelected) {
      url += '?deselectAll=true';
    }

    return this.http.patch(url, axData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  deselectAnnuity(id, note, annuity): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const profile = id;

    const axData = {
      'CurrentBridgeAction.ActiveFlag': 1,
      'CurrentBridgeAction.AcceptType': 2201,
      'CurrentBridgeAction.FWDateType': '',
      'CurrentBridgeAction.Fwdate': '',
      'CurrentBridgeAction.Note': 'Selection Removed',
      sysActions: 0,
      bridgeId: annuity,
      actionId: 2201,
    };

    const url = this.baseUrl + '/Select/Annuities/' + profile;

    return this.http.patch(url, axData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        this.logout();
        return this.errSvc.handleError(error);
      })
    );
  }

  FiaGridData(type): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/GridData/' + type;
    return this.http
      .get(url, { headers: reqHeaders, reportProgress: true })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getReport(
    profile,
    details?,
    silent = false,
    vaDetails = false,
    validate = false
  ): Observable<Blob> {
    const id = this.ss.get('session');
    this.firm = this.firm && this.firm.length ? this.firm : this.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const app = this.app === 'rb' || this.app === 'summary' ? 'pp' : this.app;

    let url =
      this.baseUrl + '/reports/' + app + '/' + profile + '?firmId=' + this.firm;

    if (
      (this.app.toLowerCase() == 'iw' || this.app.toLowerCase() == 'pp') &&
      details &&
      details == true
    ) {
      url += '&includeDetail=true';
    }

    if (this.app.toLowerCase() == 'aw') {
      url += '&selectedBridges=' + details;
    }

    if (vaDetails) {
      url += '&includeVAInvestmentOptions=true';
    }

    if (silent) {
      url += '&returnReport=false';
    }

    if (validate) {
      url += '&isValidation=true';
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getRolloverReport(profile, details?): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');

    const url = `${this.baseUrl}/reports/PPRollover/${profile}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  silentPrintPpReport(profile, details?): Observable<Blob> {
    const id = this.ss.get('session');
    this.firm = this.firm && this.firm.length ? this.firm : this.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');

    const url =
      this.baseUrl +
      '/reports/pp/' +
      profile +
      '?firmId=' +
      this.firm +
      '&returnReport=false&includeDetail=true';

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getExchangeReport(profile, details?, silent?): Observable<Blob> {
    const id = this.ss.get('session');
    this.firm = this.firm && this.firm.length ? this.firm : this.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    let url =
      this.baseUrl + '/reports/AWExchange/' + profile + '?firmId=' + this.firm;

    url += '&selectedBridges=' + details;

    if (silent) {
      url += '&returnReport=false';
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getClientReport(profiles, options, silentPrint = false): Observable<Blob> {
    const id = this.ss.get('session');
    this.firm = this.firm && this.firm.length ? this.firm : this.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const data = { profileIds: profiles };

    if (options.bridges && options.bridges.length) {
      data['bridgeIds'] = options.bridges;
      options.threshold = 0;
    }

    const returnReport = silentPrint ? false : true;

    const url = `${this.baseUrl}/reports/cp?returnReport=${returnReport}&minBridgeScore=${options.threshold}&includeAnnualReview=${options.annReview}&includeNeedSummary=${options.needSummary}&includeProductSummary=${options.prodSummary}&includeNeedDetail=${options.needDetail}&includeProductDetail=${options.prodDetail}`;

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getAnnuityFactSheet(contract, cusip, date): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url =
      this.baseUrl + '/Reports/AWFacts/' + contract + '/' + cusip + '/' + date;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  getHistoricalFactSheet(data): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf')
      .set('Content-Type', 'application/json');
    const url = this.baseUrl + '/Reports/HistoricalLookupFactSheet/';

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  logout() {
    this.dialogRef.closeAll();
    this.endSession();
    this.zone.run(() => {
      this.router.navigate(['/login']);
    });
  }

  startProxy(user): Observable<any> {
    const userId = user.userID;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.sessionId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('X-RB-U', userId);

    const url = this.baseUrl + '/Session/proxy';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        this.getGlobals().subscribe();
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  forgotPassword(data): Observable<any> {
    const userId = data;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('X-RB-U', userId);

    const url = this.baseUrl + '/Session/forgotpassword';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  updatePassword(data): Observable<any> {
    const sess = this.ss.get('session');
    const globals = this.ss.get('globals');
    const userId = globals ? globals.user.id : null;
    const newPass = data.newPass;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('X-RB-U', userId)
      .set('X-RB-P', newPass);

    const url = this.baseUrl + '/Admin/updatepassword';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  postback(profile, app?): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', app || this.app)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/External/PostBack/' + profile;
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  extPostback(profile, solution?): Observable<any> {
    const sess = this.sessionId,
      customReportSolution = solution ? solution : this.app;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', customReportSolution)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/External/PostBackURL/' + profile;
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  extPostbackData(profile, data, solution?): Observable<any> {
    const sess = this.sessionId;
    const reportSolution = solution ? solution : this.app;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', reportSolution)
      .set('X-RB-O', this.org);

    const url = this.baseUrl + '/External/PostBackURL/' + profile;
    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAnnuityCarriers(): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Annuities/Carriers';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCarrierGroups(includeAll = false): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Annuities/UnitCarriers?includeAll=${includeAll}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRequest(url, headers = null, app?): Observable<any> {
    const requestApp = app ? app : this.app;

    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', requestApp)
        .set('X-RB-O', this.org);
    }
    return this.http.get(url, { headers: headers }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRequestText(url, headers = null): Observable<any> {
    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', this.app)
        .set('X-RB-O', this.org);
    }
    return this.http.get(url, { headers: headers, responseType: 'text' }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  baseHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('X-RB-S', this.sessionId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
  }

  patchRequest(url, body = null, headers = null): Observable<any> {
    if (headers == null) {
      headers = this.baseHeaders();
    }
    return this.http.patch(url, body, { headers: headers }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  postRequest(
    url,
    body = null,
    headers = null,
    response = null,
    app?
  ): Observable<any> {
    const requestApp = app ? app : this.app;

    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', requestApp)
        .set('X-RB-O', this.org);
    }

    return this.http.post(url, body, { headers: headers }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  postRequestForFile(url, body = null, headers = null): Observable<Blob> {
    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', this.app)
        .set('X-RB-O', this.org)
        .set('Accept', 'application/pdf')
        .set('Content-Type', 'application/json');
    }
    return this.http
      .post(url, body, {
        headers: headers,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  postRequestForCsv(
    url,
    body = null,
    headers = null
  ): Observable<string | Text> {
    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', this.app)
        .set('X-RB-O', this.org)
        .set('Accept', 'application/csv')
        .set('Content-Type', 'application/csv');
    }
    return this.http
      .post(url, body, {
        headers: headers,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  deleteRequest(url, headers = null): Observable<any> {
    if (headers == null) {
      const sess = this.sessionId;
      headers = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', this.app)
        .set('X-RB-O', this.org);
    }
    return this.http.delete(url, { headers: headers }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCarrierAnnuities(code): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Annuities/CarrierAnnuities?carrierCode=' + code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getGroupAnnuities(code): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Annuities/UnitProductsByCarrier?carrierGroupId=' + code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRegulationAnnuities(code, state, purpose, accountType): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl +
      '/Annuities/UnitProductsByTrack?carrierGroupId=' +
      code +
      '&state=' +
      state +
      '&annuityPurpose=' +
      purpose +
      '&accountTypePreference=' +
      accountType;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getValidateAnnuities(code): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Annuities/UnitProductsByCarrier?carrierGroupId=' + code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getClientSummary(profile): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Process/ClientView/${profile}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getUnifiedPrintParameters(id) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.sessionId)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${id}?groupName=PrintSettings&saveProfile=false`;
    return this.getRequest(url, reqHeaders);
  }

  getCustomPrintParameters(id, group) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.sessionId)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${id}?groupName=${group}&saveProfile=false`;
    return this.getRequest(url, reqHeaders);
  }

  getUnifiedReport(data, setApp?) {
    const sess = this.sessionId;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Reports/MultiReport`;

    return this.http
      .post(url, data, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getCustomReport(data, app) {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Reports/Custom`;

    return this.http
      .post(url, data, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getNotes(profile): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Notes/${profile}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  addNote(profile, note): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Notes/Add/${profile}?noteType=0&noteText=${note.text}&isPinned=${note.pinned}&isAlert=${note.alert}`;

    return this.http.post(url, null, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPershingGroupProfile(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.sessionId)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${profile}?groupName=PershingModal&saveProfile=false`;
    return this.getRequest(url, reqHeaders);
  }

  pinToggle(profile, noteId, pinStatus): Observable<any> {
    const sess = this.sessionId;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Notes/Pin/${profile}/${noteId}?isPinned=${pinStatus}`;

    return this.http.patch(url, null, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  signLicenseAgreement(sig) {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/User/LicenseAttest?Signature=${sig}`;
    return this.http.post(url, null, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  setFirmId(glbls?, useGlobalFirm = false) {
    const repUnits = this.ss.get('repUnits') || null;
    const firm = glbls ? glbls.firmId : this.ss.get('globals')?.firmId;

    if (repUnits && repUnits.length == 1 && !useGlobalFirm) {
      return repUnits[0].id;
    }
    return firm;
  }

  setApp(app) {
    if (app == 'summary') {
      this.app = 'rb';
    } else {
      this.app = app;
    }
    this.ss.set('currentApp', app);
  }

  authenticate(): Observable<string> {
    const sess = this.ss.get('session'),
      globals = this.ss.get('globals'),
      userId = globals ? globals.user.id : null;

    const encUser = this.encodeEmail(userId);

    const reqHeaders = new HttpHeaders()
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('X-RB-U', encUser + '@rightbridge.net')
      .set('X-RB-S', sess)
      .set('Accept', 'text/html')
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const body = '{"attributes":[{"firmId":"cr"},{"unitId":"CNO"}]}';

    const url =
      this.baseUrl +
      '/Session/createusersamlresponse?entityId=CapitalRock&recipient=recipient&audience=audience';

    return this.http
      .post(url, body, { headers: reqHeaders, responseType: 'text' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error: ', error);
        })
      );
  }

  encodeEmail(value, isDecrypt?) {
    if (isDecrypt) {
      for (let i = 0; i < this.key.length; i++)
        this.key[i] = (26 - this.key[i]) % 26;
    }
    return this.encrypt(value, this.key);
  }

  encrypt(plainText, keyword) {
    if (typeof plainText !== 'string') {
      return 'invalid plainText. Must be string, not ' + typeof plainText;
    }
    if (typeof keyword !== 'string') {
      return 'invalid keyword. Must be string, not ' + typeof keyword;
    }

    plainText = plainText.toLowerCase();
    keyword = keyword.match(/[a-z]/gi).join('').toLowerCase();
    let encryptedText = '';
    let specialCharacterCount = 0;

    for (let i = 0; i < plainText.length; i++) {
      const keyLetter = (i - specialCharacterCount) % keyword.length;
      const keywordIndex = this.tabulaRecta.a.indexOf(keyword[keyLetter]);

      if (this.tabulaRecta[plainText[i]]) {
        encryptedText += this.tabulaRecta[plainText[i]][keywordIndex];
      } else {
        encryptedText += plainText[i];
        specialCharacterCount++;
      }
    }

    return encryptedText;
  }

  getGroupProfile(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.getRequest(
      `${this.baseUrl}/GroupProfile/${profile}?groupName=${profile}&saveProfile=false`,
      reqHeaders
    );
  }

  postGroupProfile(group, app?) {
    return this.postRequest(
      `${this.baseUrl}/GroupProfile?groupName=${group}&saveProfile=false`,
      null,
      null,
      null,
      app
    );
  }

  getToolsPageGroup() {
    return this.postRequest(
      `${this.baseUrl}/GroupProfile?groupName=Tools`,
      null,
      null,
      null,
      'rb'
    );
  }

  getLandingPageGroup() {
    return this.postRequest(
      `${this.baseUrl}/GroupProfile?groupName=LandingPage`,
      null,
      null,
      null,
      'rb'
    );
  }

  getf5500Products(token, params, rlc?) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('Authorization', `Bearer ${token}`);

    let url = `${this.f5500BaseUrl}/products/product2020?Action=SEARCHPLANSAPI&RowsToReturn=200&grid=true`;
    url += rlc ? `&HasRLCData=true&${params}` : `&${params}`;

    return this.getRequest(url, reqHeaders);
  }
}
