import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DateTime } from 'luxon';
import { DashboardService } from '../dashboard/dashboard.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  CompositeFilterDescriptor,
  distinct,
  State,
} from '@progress/kendo-data-query';
import { MatDialog } from '@angular/material/dialog';
import { HierarchyModalComponent } from '../universal-search/hierarchy-modal/hierarchy-modal.component';
import { cloneDeep } from 'lodash';
import { EnvironmentService } from '../services/environment.service';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { interval } from 'rxjs';
import { mergeMap, takeWhile, map } from 'rxjs/operators';
import { SessionStorageService } from '../services/session-storage.service';

@Component({
  selector: 'app-case-explorer',
  templateUrl: './case-explorer.component.html',
  styleUrls: ['./case-explorer.component.scss'],
})
export class CaseExplorerComponent implements OnInit {
  private environment;
  loading = false;
  unitsManaged = this.ss.get('globals')
    ? this.ss.get('globals').user.managerUnits
    : {};
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().minus({ days: 7 }).toUTC().startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  gridData = { headers: [], data: [], total: 0 };
  solutions = [];
  solution = 'pp';
  hidden = [];
  flatOrGrouped = 'GROUP';
  iteration = 'CURRENT';
  profile;

  selectedUser = null;
  selectedUnit = null;
  selectedRepCode = null;

  userSearch = null;
  unitSearch = null;
  repCodeSearch = null;

  chooseHierarchy = true;
  limit30 = true;

  scoreClass;
  rights = [];
  globals = {};

  checkState = {
    startDate: DateTime.now().minus({ days: 30 }).toUTC().toISO(),
    endDate: DateTime.now().toUTC().toISO(),
    flatOrGrouped: 'GROUP',
    solution: 'pp',
    iteration: 'CURRENT',
  };
  private baseUrl: string;

  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public pageSize = 100;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  hideGrid = false;
  public pageSizeOptions = [
    this.pageSize,
    this.pageSize * 2,
    this.pageSize * 3,
  ];
  externalFilters = [
    {
      label: 'age',
      name: 'age',
      controlType: 'integer', // "states", "integer", "dropdown"
      filterType: 'between', // "equal", "between"
      between1: 'minAge', // only valid if filterType is "between"
      between2: 'maxAge', // only valid if filterType is "between"
      filterGroup: 'primary', // determines if primary filter or secondary filter placement (img below)
    },
    {
      label: 'text',
      name: 'text',
      controlType: 'text', // "states", "integer", "dropdown"
      filterGroup: 'primary', // determines if primary filter or secondary filter placement (img below)
    },
    {
      label: 'States',
      name: 'states',
      controlType: 'states', // "states", "integer", "dropdown"
      filterGroup: 'primary', // determines if primary filter or secondary filter placement (img below)
    },
    {
      label: 'dropdown',
      name: 'dropdown',
      controlType: 'dropdown', // "states", "integer", "dropdown"
      filterGroup: 'secondary', // determines if primary filter or secondary filter placement (img below)
      options: [
        { display: 'label 1', value: 1 },
        { display: 'label 2', value: 2 },
        { display: 'label 3', value: 3 },
      ],
    },
    {
      label: 'multiselect',
      name: 'multiselect',
      controlType: 'multiselect', // "states", "integer", "dropdown"
      filterGroup: 'secondary', // determines if primary filter or secondary filter placement (img below)
      options: [
        { display: 'label 1', value: 1 },
        { display: 'label 2', value: 2 },
        { display: 'label 3', value: 3 },
      ],
    },
  ];

  constructor(
    private ss: SessionStorageService,
    private dbs: DashboardService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private envSvc: EnvironmentService,
    private pltfrm: Platform
  ) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.rights = this.ss.get('rights') ? this.ss.get('rights') : [];
    this.limit30 = this.rights.includes('limit30Days');

    this.route.paramMap.subscribe(params => {
      if (params['params']['user']) {
        this.selectedUser = {
          name: params['params']['user'],
          id: params['params']['user'],
        };
        this.chooseHierarchy = false;
      }
      if (params['params']['scoreClass']) {
        this.scoreClass = params['params']['scoreClass'];
      }
    });

    this.globals = this.ss.get('globals');
    if (this.globals) {
      this.globals['Modules'].forEach(el => {
        switch (el.toLowerCase()) {
          case 'aw':
            this.solutions.push({
              label: 'Annuity Wizard',
              value: el.toLowerCase(),
            });
            break;
          case 'iw':
            this.solutions.push({
              label: 'Investment Wizard',
              value: el.toLowerCase(),
            });
            break;
          case 'li':
            this.solutions.push({
              label: 'Life Insurance Wizard',
              value: el.toLowerCase(),
            });
            break;
          case 'pp':
            this.solutions.push({
              label: 'Product Profiler',
              value: el.toLowerCase(),
            });
            break;
          default:
            break;
        }
      });
    }

    const solutionsSort = ['pp', 'aw', 'iw', 'li', 'rw', 'cp', 'se'];
    this.solutions.sort(
      (a, b) => solutionsSort.indexOf(a.value) - solutionsSort.indexOf(b.value)
    );
    this.getCaseStatsSummary();
  }

  getCaseStatsSummary() {
    this.loading = true;
    let unit = this.unit;
    let user = null;
    const back30 = DateTime.now().minus({ days: 30 }).toISO();
    const start = this.limit30
      ? back30
      : DateTime.fromJSDate(new Date(this.startDate.value)).toISO();
    const end = this.limit30
      ? DateTime.now().toISO()
      : DateTime.fromJSDate(new Date(this.endDate.value)).toISO();

    if (this.selectedUnit) {
      unit = this.selectedUnit.id;
    }
    if (this.selectedUser) {
      user = this.selectedUser.id;
    }

    this.checkState = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      flatOrGrouped: this.flatOrGrouped,
      solution: this.solution,
      iteration: this.iteration,
    };

    this.dbs
      .getCaseSummaryStats(
        unit,
        'rb',
        start,
        end,
        this.state.take,
        this.state.skip,
        true,
        user,
        this.flatOrGrouped,
        false,
        this.iteration,
        this.solution
      )
      .subscribe(x => {
        this.gridData = {
          data: x.results.Data,
          headers: x.results.Headers,
          total: x.results.Data.length,
        };
        this.setColumnVisiblity();
        this.hideGrid = false;
        this.loading = false;
      });

    if (this.profile) {
      this.filter.filters = [
        { field: 'ProfileID', operator: 'eq', value: this.profile },
      ];
    }
  }

  disableSearch() {
    if (
      DateTime.fromISO(
        this.checkState.startDate.value
          ? this.checkState.startDate.value
          : this.checkState.startDate
      ).toFormat('MM/dd/yyyy') !==
        DateTime.fromISO(this.startDate.value).toFormat('MM/dd/yyyy') ||
      DateTime.fromISO(
        this.checkState.endDate.value
          ? this.checkState.endDate.value
          : this.checkState.endDate
      ).toFormat('MM/dd/yyyy') !==
        DateTime.fromISO(this.endDate.value).toFormat('MM/dd/yyyy') ||
      this.checkState.flatOrGrouped !== this.flatOrGrouped ||
      this.checkState.solution !== this.solution ||
      this.checkState.iteration !== this.iteration
    ) {
      return false;
    }
    return true;
  }

  public distinctPrimitive(fieldName: string): any {
    return distinct(this.gridData.data, fieldName).map(item => item[fieldName]);
  }

  setColumnVisiblity() {
    this.hidden = [];
    let subCount = 0;
    this.gridData.headers?.forEach(x => {
      if (x.subgroup) {
        if (subCount < 1) {
          this.gridData['subHead'] = [];
        }

        this.gridData['subHead'].push(x);
        this.gridData.headers = this.gridData.headers.filter(z => z != x);

        subCount++;
      }
      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          // this.hideColumn(x.DataField);
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }

      if (x.Hidden) {
        // let idx = this.gridData.Headers.findIndex(z => z.DataField === x.DataField);
        // this.gridData.Headers.splice(idx, 1);
        this.hidden.push(x.DataField);
      }
    });

    this.hidden.forEach(x => {
      this.hideColumn(x);
    });

    // this.resultsGrid.autoFitColumns();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
    }
  }

  resetGrid() {
    this.selectedUser = null;
    this.selectedUnit = null;
    this.filter = null;
    this.sort = [];
    this.checkState = {
      startDate: DateTime.now().minus({ days: 30 }).toUTC().toISO(),
      endDate: DateTime.now().toUTC().toISO(),
      flatOrGrouped: 'GROUP',
      solution: 'pp',
      iteration: 'CURRENT',
    };
    this.startDate = new FormControl(
      DateTime.now().minus({ days: 7 }).toUTC().startOf('day').toISO()
    );
    this.endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
    this.getCaseStatsSummary();
  }

  findValue(val, list) {
    if (val || val === 0) {
      const display = list.find(x => x.value == val)
        ? list.find(x => x.value == val).label
        : val;
      return display;
    }
    return null;
  }

  setHierarchy() {
    const dialogRef = this.dialog.open(HierarchyModalComponent, {
      panelClass: 'hierarchy-dialog',
      minHeight: '30vh',
      width: '50vw',
      data: { mode: 'caseExplorer' },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedUnit = result.data.unit || null;
      this.selectedUser = result.data.user || null;
      this.chooseHierarchy = !this.selectedUnit && !this.selectedUser;
      this.getCaseStatsSummary();
    });
  }

  clearHierarchy() {
    this.selectedUnit = null;
    this.selectedUser = null;
    this.selectedRepCode = null;
    this.chooseHierarchy = !this.selectedUnit && !this.selectedUser;
    this.getCaseStatsSummary();
  }

  viewCase(ev, dataItem) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (this.globals['GlobalsGroup']['UnitInfo']['TurnOffSummaryPage']) {
      const app = this.solutions[0].value;
      window.open(
        `${this.location.prepareExternalUrl('')}case/${app}/${
          dataItem.ProfileID
        }/results`,
        '_blank'
      );
    } else {
      window.open(
        `${this.location.prepareExternalUrl('')}case/summary/${
          dataItem.ProfileID
        }`,
        '_blank'
      );
    }
  }

  viewHistory(ev, data) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    // let solution = this.solution == 'ALL' ? null : this.solution;

    window.open(
      `${this.location.prepareExternalUrl('')}profile-history/${
        this.solution
      }/${data.ProfileID}`,
      '_blank'
    );
  }

  exportToExcel(grid: GridComponent): void {
    const newGrid = cloneDeep(grid);
    newGrid.saveAsExcel();
  }

  dlXlsx() {
    this.loading = true;
    let unit = this.unit;
    let user = null;
    const solution =
      this.solution.toLowerCase() == 'all' ? 'pp' : this.solution;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : this.startDate.value.toISOString();
    const end = this.limit30
      ? new Date().toISOString()
      : this.endDate.value.toISOString();

    if (this.selectedUnit) {
      unit = this.selectedUnit.id;
    }
    if (this.selectedUser) {
      user = this.selectedUser.id;
    }

    this.dbs
      .getExplorerExcel(unit, solution, start, end, user, false, this.iteration)
      .subscribe(data => {
        if (data.result && data.result.length > 5) {
          const status = interval(15000).pipe(
            mergeMap(() =>
              this.dbs.getFileStatus(data.result).pipe(map(z => z))
            ),
            takeWhile(x => x.result.toLowerCase() == 'false')
          );

          status.subscribe(
            val => val,
            err => console.error(err),
            () => this.dlXlsxFile(data.result)
          );
        }
      });
  }

  dlXlsxFile(name) {
    const date = new Date();
    const filename =
      'CaseExplorer-' +
      date.getMonth() +
      date.getDay() +
      date.getFullYear() +
      '.xlsx';
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs.getXlsxFile(name).subscribe(data => {
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        this.loading = false;
      };
    });
  }

  updateFilters(ev) {}

  public dataStateChange(state): void {
    this.loading = true;
    this.pageSize = state.take;
    this.state = state;
    this.filter = this.state.filter;
    this.sort = this.state.sort;
    this.getCaseStatsSummary();
  }
}
