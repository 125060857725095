import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { PostTradeService } from '../../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trade-review-download',
  templateUrl: './trade-review-download.component.html',
  styleUrls: ['./trade-review-download.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TradeReviewDownloadComponent {
  fileName = '';
  downloadRequested = false;

  constructor(
    public dialogRef: MatDialogRef<TradeReviewDownloadComponent>,
    private ptSvc: PostTradeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  downloadTradeGrid() {
    const { columns, rows, query } = this.data;
    const requestQuery = query;
    const requestCols = { columns };

    if (requestQuery.take) {
      delete requestQuery.take;
    }
    if (requestQuery.skip) {
      delete requestQuery.skip;
    }

    this.ptSvc
      .generateTradeReviewReport(
        this.fileName,
        encodeURIComponent(JSON.stringify(requestQuery)),
        encodeURIComponent(JSON.stringify(requestCols)),
        rows
      )
      .subscribe(resp => {
        if (resp['result'] === 'Report request created') {
          this.downloadRequested = true;
        }
      });
  }
}
