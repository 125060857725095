import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../../services/session-storage.service';
import { Router } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { Location } from '@angular/common';
import { ModuleStatusService } from '../../services/module-status.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  rights = [];
  disableButtons = false;
  noSaveProfile = false;
  loading = true;
  apps = ['PP', 'AW', 'IW', 'LW', 'ClientInfo'];
  unitLandingPage;
  unitTools = {};
  unitToolsHeaders = {};
  unitToolsCount = 0;
  unitToolRights = {
    pp: ['PlanLookupTool'],
    aw: [
      'ProductShelf',
      'AllowCurrentFactSheetLookup',
      'AllowHistoricalFactSheetLookup',
      'RMD Calc',
      'AnnuityGrid',
      'FIAGrid',
      'ShowVIAFunds',
    ],
    iw: ['IWProductShelf', 'FundValidate', 'create_templates'],
  };
  rightsBasedUnitToolDefaultData = {
    pp: {
      PlanLookupTool: {
        ToolName: 'Plan Lookup',
        ToolLink: 'pp/plan-lookup',
        ToolDescription: 'Search and filter 401(k)s and other ERISA plans',
        ToolExternal: false,
      },
    },
    aw: {
      ProductShelf: {
        ToolName: 'Product Shelf',
        ToolLink: 'aw/product-shelf',
        ToolDescription:
          'Review annuity and rider details in depth including the ability to compare annuities side-by-side. Perform a quick guaranteed income calculation for living benefit riders.',
        ToolExternal: false,
      },
      AllowCurrentFactSheetLookup: {
        ToolName: 'Product Lookup',
        ToolLink: 'aw/lookup',
        ToolDescription:
          'Generate a fact sheet for annuities including details on available riders.',
        ToolExternal: false,
      },
      AllowHistoricalFactSheetLookup: {
        ToolName: 'Product Lookup',
        ToolLink: 'aw/lookup',
        ToolDescription:
          'Generate a fact sheet for annuities including details on available riders.',
        ToolExternal: false,
      },
      'RMD Calc': {
        ToolName: 'RMD Calculation',
        ToolLink: 'aw/rmd-calculation',
        ToolDescription:
          'Required Minimum Distribution (RMD) Optimization Calculator',
        ToolExternal: false,
      },
    },
    iw: {
      IWProductShelf: {
        ToolName: 'Product Shelf',
        ToolLink: 'iw/product-shelf',
        ToolDescription: '',
        ToolExternal: false,
      },
      FundValidate: {
        ToolName: 'Investment Comparison',
        ToolLink: 'iw/lookup',
        ToolDescription:
          'Generate Reasonably Available Alternatives (RAAs) for a selected investment, with side-by-side fund comparison.',
        ToolExternal: false,
      },
      create_templates: {
        ToolName: 'Template Management',
        ToolLink: 'iw/templates',
        ToolDescription:
          'Create and manage "templates", or commonly used portfolios, for use within the Investment Wizard.',
        ToolExternal: false,
      },
    },
  };
  utilityApps = [
    {
      label: 'Dashboard',
      link: '/dashboard',
      right: 'NewDashboardTab',
      icon: 'dashboard',
    },
    {
      label: 'Case Management',
      link: '/case-management',
      right: 'CM',
      icon: 'business_center',
    },
    {
      label: 'Book Management',
      link: '/book-management',
      right: 'BookManagement',
      icon: 'menu_book',
    },
    {
      label: 'Post Trade',
      link: '/post-trade',
      right: 'PostTrade',
      icon: 'history_edu',
    },
  ];
  moduleLabels = {};
  toolLinks = [];
  setUpLandingPage: any;

  constructor(
    private ss: SessionStorageService,
    private router: Router,
    private rbs: RightBridgeApiService,
    private location: Location,
    public modStatusSvc: ModuleStatusService
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.noSaveProfile = this.rights?.includes('ReadOnly');

    const availableApps = [];

    if (this.rights?.includes('PP')) {
      availableApps.push('pp');
      availableApps.push('clientinfo');
    }

    if (this.rights?.includes('AW')) {
      availableApps.push('aw');
    }

    if (this.rights?.includes('IW')) {
      availableApps.push('iw');
    }

    if (this.rights?.includes('LI')) {
      availableApps.push('li');
    }

    this.ss.set('availableApps', availableApps);

    this.rbs.getLandingPageGroup().subscribe(resp => {
      this.unitLandingPage = resp.UnitLandingPage;
      this.setUpLandingPage = resp.SetUpLandingPage;
      this.apps.map(appName => {
        const unitToolsName = appName + 'UnitTools';
        let unitTools = resp[unitToolsName];
        if (this.rightsBasedUnitToolDefaultData[appName.toLowerCase()]) {
          Object.keys(
            this.rightsBasedUnitToolDefaultData[appName.toLowerCase()]
          ).forEach(key => {
            // some buttons have the same links but separate rights.
            // this checks if a copy of the link has already been added to the page.
            if (
              !this.toolLinks.includes(
                this.rightsBasedUnitToolDefaultData[appName.toLowerCase()][key]
                  .ToolLink
              )
            ) {
              if (this.rights.includes(key)) {
                unitTools.push(
                  this.rightsBasedUnitToolDefaultData[appName.toLowerCase()][
                    key
                  ]
                );
                // (if a link was added to the homepage, this keeps track for the above logic)
                this.toolLinks.push(
                  this.rightsBasedUnitToolDefaultData[appName.toLowerCase()][
                    key
                  ].ToolLink
                );
              }
            }
          });
        }
        if (unitTools) {
          unitTools = unitTools.filter(
            (item: {
              ToolName: string;
              ToolDescription: string;
              ToolExternal: boolean;
              ToolLink: string;
            }) => {
              if (item.ToolName) {
                return item;
              }
            }
          );
        }
        const moduleLabelName = 'Control' + appName + 'Settings';
        if (
          (unitTools && unitTools.length > 0) ||
          (resp['UnitToolsHeaders'] &&
            resp['UnitToolsHeaders'][appName + 'Title'])
        ) {
          this.unitTools[unitToolsName] = unitTools;
          this.unitToolsHeaders[appName + 'Title'] =
            resp['UnitToolsHeaders'][appName + 'Title'];
          this.unitToolsHeaders[appName + 'Description'] =
            resp['UnitToolsHeaders'][appName + 'HeaderDescription'];
          this.unitToolsCount++;
        }
        if (resp[moduleLabelName]) {
          this.moduleLabels[appName] = resp[moduleLabelName]['ModuleName'];
        }
        resp[unitToolsName] = unitTools;
      });
      this.ss.set('landing', resp);
      this.loading = false;
    });
  }

  goToQuestionnaire(app) {
    let setApp;

    if (app === 'pp') {
      setApp = 'rb';
    } else if (app === 'lw') {
      setApp = 'li';
    } else {
      setApp = app;
    }
    this.modStatusSvc.apps.set(['rb']);
    this.disableButtons = true;
    this.ss.set('currentApp', setApp);
    this.rbs.setApp(setApp);
    if (!this.noSaveProfile) {
      this.rbs.newProfile({ 'Profile.Description': '' }, true).subscribe(x => {
        this.router.navigate([`/case/${setApp}/${x.results}/0`]);
      });
    } else {
      const profile = this.getUniqueId(3);
      this.ss.set('noSave', {
        id: profile,
        profile: null,
        questionnaire: null,
      });
      this.router.navigate([`/case/${setApp}/${profile}/0`]);
    }
  }

  onRightClick(link) {
    window.open(`${this.location.prepareExternalUrl('/')}${link}`, '_blank');
  }

  onLinkClick(e, link) {
    if (e.ctrlKey) {
      window.open(`${this.location.prepareExternalUrl('/')}${link}`, '_blank');
    } else {
      this.router.navigate([link]);
    }
  }

  onInternalLink(link, name) {
    if (name.toLowerCase().includes('wizard')) {
      this.goToQuestionnaire(link);
    } else {
      window.open(`${this.location.prepareExternalUrl('/')}${link}`, '_blank');
    }
  }

  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  goToPage(link: string): void {
    this.router.navigate([link]);
  }

  unitToolsCheck() {
    if (!this.unitTools || Object.keys(this.unitTools).length === 0) {
      return false;
    }
    return true;
  }

  defaultTitle(appName) {
    if (appName === 'PP') {
      return 'Product';
    } else if (appName === 'AW') {
      return 'Annuity';
    } else if (appName === 'IW') {
      return 'Investment';
    } else if (appName === 'LW') {
      return 'Life Insurance';
    }
  }

  toolsWidthCheck(parentContainer = false) {
    if (
      parentContainer &&
      this.unitLandingPage &&
      this.unitLandingPage.GeneralDescriptionShow
    ) {
      if (this.unitToolsCount === 1) {
        return `col-md-3`;
      } else if (this.unitToolsCount === 2) {
        return `col-md-6`;
      } else if (this.unitToolsCount >= 3) {
        return `col-md-9`;
      }
    } else if (
      parentContainer &&
      this.unitLandingPage &&
      !this.unitLandingPage.GeneralDescriptionShow
    ) {
      if (this.unitToolsCount === 1) {
        return `col-md-3`;
      } else if (this.unitToolsCount === 2) {
        return `col-md-6`;
      } else if (this.unitToolsCount >= 3) {
        return `col-md-12`;
      }
    } else {
      if (this.unitToolsCount === 1) {
        return ``;
      } else if (this.unitToolsCount === 2) {
        return `col-md-6`;
      } else if (this.unitToolsCount >= 3) {
        return `col-md-3`;
      }
      return `col-md-3`;
    }

    return '';
  }
}
