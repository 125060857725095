import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

//Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highcharts3d from 'highcharts/highcharts-3d.src';
import { HighchartsChartModule } from 'highcharts-angular';
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, exporting, highcharts3d];
}

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';
import { MatSelectModule } from '@angular/material/select';
import { PostTradeComponent } from './post-trade-shell/post-trade.component';
import { PostTradeReportsComponent } from './post-trade-reports/post-trade-reports.component';
import { TradeReviewComponent } from './trade-review/trade-review-shell/trade-review.component';
import { TradeReviewDashboardComponent } from './trade-review/trade-review-dashboard/trade-review-dashboard.component';
import { TradeReviewDetailComponent } from './trade-review/trade-review-detail/trade-review-detail.component';
import { TradeHistoryDialogComponent } from './trade-review/trade-review-detail/trade-history-dialog/trade-history-dialog.component';
import { TradeReviewGridComponent } from './trade-review/trade-review-grid/trade-review-grid.component';
import { TradeReviewDownloadComponent } from './trade-review/trade-review-download/trade-review-download.component';
import { AccountReviewComponent } from './account-review/account-review-shell/account-review.component';
import { AccountReviewGridComponent } from './account-review/account-review-grid/account-review-grid.component';
import { AccountReviewDetailComponent } from './account-review/account-review-detail/account-review-detail.component';

//Kendo Imports
import { GridModule } from '@progress/kendo-angular-grid';

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { PostTradeService } from './post-trade.service';

import { PostTradeRouting } from './post-trade-routing.module';

@NgModule({
  imports: [
    MatSelectModule,
    CommonModule,
    RouterModule,
    PostTradeRouting,
    FormsModule,
    ReactiveFormsModule,
    ConditionalFormModule,
    NgxDatatableModule,
    GridModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    SharedComponentsModule,
    HighchartsChartModule,
    ChartModule,
  ],
  declarations: [
    PostTradeComponent,
    PostTradeReportsComponent,
    TradeReviewComponent,
    TradeReviewDashboardComponent,
    TradeReviewDetailComponent,
    TradeHistoryDialogComponent,
    TradeReviewGridComponent,
    TradeReviewDownloadComponent,
    AccountReviewComponent,
    AccountReviewGridComponent,
    AccountReviewDetailComponent,
  ],
  providers: [
    RightBridgeApiService,
    PostTradeService,
    AuthService,
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PostTradeModule {}
