import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { accountInfoKey } from './account-review-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-account-review-detail',
  templateUrl: './account-review-detail.component.html',
  styleUrls: ['./account-review-detail.component.scss'],
})
export class AccountReviewDetailComponent implements OnInit, OnDestroy {
  @Input() accountId;
  accountDetail;
  accountInfoKey = accountInfoKey;
  isEmpty = isEmpty;
  mutualFundRows = 5;
  tradeHistoryRows = 5;
  productRecommendations = {};
  productModules = [];

  constructor(
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.ptSvc.getAccountDetail(this.accountId).subscribe(resp => {
      this.accountDetail = resp;
      // this.sortProductRecommendations(resp['Details']['ProductSelected']);
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  sortProductRecommendations(productList) {
    if (productList && productList.length > 0) {
      productList.map(product => {
        if (this.productRecommendations[product.Module]) {
          this.productRecommendations[product.Module].push(product);
        } else {
          this.productModules.push(product.Module);
          this.productRecommendations[product.Module] = [product];
        }
      });
    }
  }

  returnZero() {
    return 0;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  approveTrade() {
    this.loading = true;
    this.ptSvc
      .updateTrade({ AccountID: [this.accountId], ApprovalStatus: 'APP' })
      .subscribe(() => {
        this.ptSvc.getAccountDetail(this.accountId).subscribe(resp => {
          this.snacky.open(`${this.accountId} was updated!`, 'Close', {
            duration: 4000,
          });

          this.accountDetail = resp;
          this.loading = false;
        });
      });
  }

  downloadFactSheet() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  toggleRows(rowProp, value) {
    if (this[rowProp] !== value) {
      this[rowProp] = value;
    } else {
      this[rowProp] = 5;
    }
  }
}
