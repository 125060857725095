export const accountInfoKey = [
  {
    label: 'Account Balance',
    varName: 'AccountBalance',
    valueMask: 'currency',
  },
  { label: 'Rep Code', varName: 'RepCode' },
  { label: 'Open Date', varName: 'AccountOpenedDate' },
  { label: 'Last Activity Date', varName: 'LastActivityDate' },
  { label: 'Processed Date', varName: 'ProcessedDate' },
  { label: 'Review Date', varName: 'ReviewDate' },
];
export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
