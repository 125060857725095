<div class="page-container account-review-grid-container">
  <h1 class="header col-12 mb-2"> Account Review </h1>
  @if (!hideGrid) {
    <div class="row mb-0 mx-1">
      <app-grid-filters
        class="col-12"
        [gridName]="'AccountReviewGrid'"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
      ></app-grid-filters>
    </div>
    <div class="row mb-0 mx-1 grid-controls">
      <app-grid-column-filter
        [columns]="gridData.headers"
        (columnsUpdated)="externalColumnsUpdated($event)"
        class="ml-auto mr-2"
      ></app-grid-column-filter>

      <button mat-button color="primary" class="mr-0" (click)="reset()">
        <mat-icon class="mr-2">replay</mat-icon>Reset
      </button>
    </div>

    <kendo-grid
      class="col-12 mb-5"
      #accountReviewGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip * 1"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="false"
      [filter]="filter"
      [columnMenu]="{ columnChooser: false }"
      [state]="state"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-column width="125" title="Actions">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="action-buttons-container">
            <button
              mat-icon-button
              class="fs-button"
              (click)="viewAccount(dataItem)"
              color="primary"
              matTooltip="View Account"
            >
              <mat-icon>preview</mat-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <ng-template ngFor [ngForOf]="gridData.headers" let-column>
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [width]="125"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-multiselect
              style="width: 99%; margin-top: -30px"
              [data]="filterData[column.DataField]"
              textField="display"
              valueField="value"
              [valuePrimitive]="true"
              [value]="filter | filterValues"
              (valueChange)="
                filterValueChange($event, column.DataField, filterService)
              "
              [fillMode]="'outline'"
              placeholder="Filter By..."
            ></kendo-multiselect>
          </ng-template>
          @if (column.Type === 'integer') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | number: '1.0-0' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | number: '1.0-0' }}
            </ng-template>
          } @else if (column.Type === 'perc') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent }}
            </ng-template>
          } @else if (column.Type === 'perc2') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent: '1.2' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent: '1.2' }}
            </ng-template>
          } @else if (column.Type === 'curr') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | currency }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | currency }}
            </ng-template>
          } @else if (column.Type === 'date') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | date: 'short' }}
            </ng-template>
          } @else if (column.DataField === 'notes') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          } @else if (column.DataField === 'ScoreClass') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="scoreclass-container">
                <mat-icon class="ml-0" [class]="dataItem.ScoreClass">
                  {{ scoreClassKey[dataItem.ScoreClass] }}
                </mat-icon>
              </div>
            </ng-template>
          } @else if (column.DataField === 'AlertIDs') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ alertListDisplay(dataItem[column.DataField]) }}
            </ng-template>
          } @else if (column.Validation) {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span
                [innerHtml]="displayValue(column, dataItem[column.DataField])"
              ></span>
            </ng-template>
          }
        </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
