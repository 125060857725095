<div class="page-container account-review-detail-container container pt-2">
  <h1 class="header col-12 mb-1"> Account Details - {{ accountId }} </h1>
  @if (!loading) {
    <div class="actions-container mb-3">
      <button mat-button (click)="nav('account-review')" color="primary">
        <mat-icon>arrow_back_ios</mat-icon> Back to Account Review
      </button>
    </div>

    <div class="trade-info-container info-card mb-4 w-100 p-0">
      <div class="card-header">
        <span class="card-title-container">
          Account ID: <span class="card-title mb-0 ml-2">{{ accountId }}</span>
        </span>
      </div>
      <div class="card-info row">
        @for (accountInfo of accountInfoKey; track accountInfo.label) {
          <div class="col-sm-2 col-12">
            <div class="card-info-label">
              {{ accountInfo.label }}
            </div>
            <div class="card-info-value">
              @if (
                accountInfo.valueMask && accountInfo.valueMask === 'currency'
              ) {
                <span>{{ accountDetail[accountInfo.varName] | currency }}</span>
              } @else {
                {{ accountDetail[accountInfo.varName] }}
              }
            </div>
          </div>
        }
      </div>
    </div>
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
