<div class="summary-container">
  <ul class="apps col-12 mb-5">
    @for (app of applications; track app.name; let idx = $index) {
      @if (
        app.name !== 'Actions' &&
        app.name !== 'ClientInfo' &&
        app.name !== 'SummaryPage'
      ) {
        <li
          class="app col-12"
          [ngClass]="{
            expanded: app.expanded,
            positive: app.vars.ModuleStatus?.FlowStatus === 'C',
            neutral:
              app.vars.ModuleStatus?.FlowStatus === 'PPF' ||
              app.vars.ModuleStatus?.FlowStatus === 'OR' ||
              app.vars.ModuleStatus?.FlowStatus === 'O',
            negative: app.vars.ModuleStatus?.FlowStatus === 'R'
          }"
        >
          <div class="col-12 row">
            <div class="col-11 content">
              <h4>
                @switch (app.name.toLowerCase()) {
                  @case ('pp') {
                    <mat-icon class="mr-1" svgIcon="pp_icon"></mat-icon>
                  }
                  @case ('aw') {
                    <mat-icon class="mr-1" svgIcon="aw_icon"></mat-icon>
                  }
                  @case ('iw') {
                    <mat-icon class="mr-1" svgIcon="iw_icon"></mat-icon>
                  }
                  @case ('li') {
                    <mat-icon class="mr-1" svgIcon="lw_icon"></mat-icon>
                  }
                }
                {{ app.outline.sections.ShortName }}
              </h4>
              <span
                class="status right-justify d-flex align-items-center"
                [ngClass]="{
                  positive: app.vars.ModuleStatus?.FlowStatus === 'C',
                  neutral:
                    app.vars.ModuleStatus?.FlowStatus === 'PPF' ||
                    app.vars.ModuleStatus?.FlowStatus === 'OR' ||
                    app.vars.ModuleStatus?.FlowStatus === 'O',
                  negative: app.vars.ModuleStatus?.FlowStatus === 'R'
                }"
              >
                @switch (app.vars.ModuleStatus?.FlowStatus) {
                  @case ('X') {
                    <!-- Not Opened -->
                    <mat-icon class="mr-1">check_circle</mat-icon> Not Required
                  }
                  @case ('R') {
                    <!-- Required Not Opened -->
                    <mat-icon class="mr-1">remove_circle</mat-icon> Required,
                    Not Opened
                  }
                  @case ('PPF') {
                    <!-- Product Profiler First -->
                    <mat-icon class="mr-1">error</mat-icon> Run Product Profiler
                    First
                  }
                  @case ('C') {
                    <!-- Completed -->
                    <mat-icon class="mr-1">check_circle</mat-icon>
                    Complete
                  }
                  @case ('OR') {
                    <!-- Open Required -->
                    <mat-icon class="mr-1">error</mat-icon> Required, In
                    Progress
                  }
                  @case ('O') {
                    <!-- Opened -->
                    <mat-icon class="mr-1">error</mat-icon> In Progress
                  }
                }
              </span>
            </div>
            <div
              class="col-1 button d-flex justify-content-center align-items-center"
            >
              <button
                mat-icon-button
                (click)="toggleExpand($event, idx)"
                class="mb-0"
              >
                <span
                  class="content-toggle-arrow d-flex justify-content-center align-items-center"
                >
                  <mat-icon>expand_circle_down</mat-icon>
                </span>
              </button>
            </div>
          </div>

          <div class="details w-95 clearfix">
            @if (!app.expanded && app.name.toLowerCase() !== 'iw') {
              <span class="caseCount">
                {{ app.vars.ProductSelected?.length }} Product
                @if (
                  app.vars.ProductSelected?.length > 1 ||
                  app.vars.ProductSelected?.length === 0
                ) {
                  s
                }

                Selected
              </span>
              @if (
                app.vars.ModuleStatus?.FlowStatus === 'OR' ||
                app.vars.ModuleStatus?.FlowStatus === 'O'
              ) {
                <button
                  mat-button
                  (click)="completeCase(app.name.toLowerCase())"
                  class="pull-right"
                  color="primary"
                >
                  <mat-icon>arrow_back</mat-icon> Complete Case
                </button>
              }
            }
            @if (app.expanded) {
              <div class="funds-container clearfix">
                @if (app.vars.ProductSelected?.length > 0) {
                  @for (
                    type of app.vars.ProductType;
                    track type.Type;
                    let i = $index
                  ) {
                    <div
                      class="sub-items"
                      [ngClass]="{
                        'mb-4': i !== app.vars.ProductType.length - 1
                      }"
                    >
                      <h5 class="mb-1">{{ type.Header }}</h5>
                      <ul class="items-list">
                        @for (
                          item of app.vars[checkProductType(type.Type)];
                          track item.Name
                        ) {
                          @if (
                            (checkProductType(type.Type) ===
                              'ProductSelected' &&
                              item.Type.toLowerCase() ===
                                type.Type.toLowerCase()) ||
                            checkProductType(type.Type) !== 'ProductSelected'
                          ) {
                            <li>
                              <span
                                class="score-indicator {{ item.ScoreClass }}"
                              ></span
                              >{{ item.Name }}
                              @if (item.Amount && item.Amount.length > 0) {
                                &mdash; {{ item.Amount }}
                              }
                            </li>
                          }
                        }
                      </ul>
                    </div>
                  }
                }
              </div>
              <div class="actions-container">
                @if (app.vars.GeneralSettings.EditInfoShow) {
                  <button
                    mat-button
                    color="primary"
                    (click)="editInfo(app.name.toLowerCase())"
                  >
                    <mat-icon class="mr-1">edit</mat-icon>
                    @if (app.vars.GeneralSettings.EditInfoText) {
                      {{ app.vars.GeneralSettings.EditInfoText }}
                    } @else {
                      Edit Information
                    }
                  </button>
                }
                @if (app.vars.GeneralSettings.ViewResultsShow) {
                  <button
                    mat-button
                    color="primary"
                    (click)="viewResults(app.name.toLowerCase())"
                  >
                    <mat-icon class="mr-1">view_list</mat-icon>
                    @if (app.vars.GeneralSettings.ViewResultsText) {
                      {{ app.vars.GeneralSettings.ViewResultsText }}
                    } @else {
                      View Results
                    }
                  </button>
                }
                @if (
                  (app.name.toLowerCase() === 'aw' &&
                    showPrint[app.name.toLowerCase() + '1'].show) ||
                  showPrint[app.name.toLowerCase()].show
                ) {
                  <button
                    mat-button
                    color="primary"
                    (click)="getReport($event, app.name.toLowerCase())"
                    [disabled]="
                      app.name.toLowerCase() === 'aw'
                        ? showPrint[app.name.toLowerCase() + '1'].disable
                        : showPrint[app.name.toLowerCase()].disable
                    "
                  >
                    <mat-icon>insert_drive_file</mat-icon>
                    {{
                      app.name.toLowerCase() === 'aw'
                        ? showPrint[app.name.toLowerCase() + '1'].text
                        : showPrint[app.name.toLowerCase()].text
                    }}
                  </button>
                }
                @if (
                  app.name.toLowerCase() === 'aw' &&
                  showPrint[app.name.toLowerCase() + '2'].show
                ) {
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      getReport($event, app.name.toLowerCase(), 'awClient')
                    "
                    [disabled]="showPrint[app.name.toLowerCase() + '2'].disable"
                  >
                    <mat-icon>insert_drive_file</mat-icon>
                    {{
                      app.name.toLowerCase() === 'aw'
                        ? showPrint[app.name.toLowerCase() + '2'].text
                        : showPrint[app.name.toLowerCase() + '2'].text
                    }}
                  </button>
                }
                @if (
                  app.name.toLowerCase() === 'aw' &&
                  showPrint[app.name.toLowerCase() + '3'].show
                ) {
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      getReport($event, app.name.toLowerCase(), 'exchange')
                    "
                    [disabled]="showPrint[app.name.toLowerCase() + '3'].disable"
                  >
                    <mat-icon>insert_drive_file</mat-icon>
                    {{
                      app.name.toLowerCase() === 'aw'
                        ? showPrint[app.name.toLowerCase() + '3'].text
                        : showPrint[app.name.toLowerCase() + '3'].text
                    }}
                  </button>
                }
                @if (
                  app.vars.ButtonSettings.ButtonShow ||
                  app.vars.ButtonSettings.ButtonShow2 ||
                  app.vars.ButtonSettings.ButtonShow3
                ) {
                  <div class="action-button-container pt-3 ml-3">
                    @if (app.vars.ButtonSettings.ButtonShow) {
                      <button
                        class="mr-2"
                        mat-stroked-button
                        color="primary"
                        (click)="
                          submitCase(
                            $event,
                            app.name.toLowerCase(),
                            app.vars.ButtonSettings
                          )
                        "
                        [disabled]="!app.vars.ButtonSettings.ButtonActive"
                      >
                        {{ app.vars.ButtonSettings.ButtonText
                        }}<mat-icon>navigate_next</mat-icon>
                      </button>
                    }
                    @if (app.vars.ButtonSettings.ButtonShow2) {
                      <button
                        class="mr-2"
                        mat-stroked-button
                        color="primary"
                        (click)="
                          submitCase(
                            $event,
                            app.name.toLowerCase(),
                            app.vars.ButtonSettings,
                            2
                          )
                        "
                        [disabled]="!app.vars.ButtonSettings.ButtonActive2"
                      >
                        {{ app.vars.ButtonSettings.ButtonText2
                        }}<mat-icon>navigate_next</mat-icon>
                      </button>
                    }
                    @if (app.vars.ButtonSettings.ButtonShow3) {
                      <button
                        class="mr-2"
                        mat-stroked-button
                        color="primary"
                        (click)="
                          submitCase(
                            $event,
                            app.name.toLowerCase(),
                            app.vars.ButtonSettings,
                            3
                          )
                        "
                        [disabled]="!app.vars.ButtonSettings.ButtonActive3"
                      >
                        {{ app.vars.ButtonSettings.ButtonText3
                        }}<mat-icon>navigate_next</mat-icon>
                      </button>
                    }
                  </div>
                }
                @if (app.vars.GeneralSettings.ShowInstructionText) {
                  <div class="px-4 instruction-text">
                    {{ app.vars.GeneralSettings.InstructionText }}
                  </div>
                }
              </div>
            }
          </div>
          @if (app.expanded && app.vars.GeneralSettings.ReasonTextShow) {
            <div class="reasons-container px-5 mb-5">
              <ul class="reason-list">
                @for (reason of app.trueRules; track reason) {
                  @if (reason.headline) {
                    <li>
                      {{ reason.headline }}
                    </li>
                  }
                }
              </ul>
            </div>
          }
        </li>
      }
    }
  </ul>
  @if (buttonInstructions?.ShowInstructionText) {
    <div class="mb-3 instruction-text">
      {{ buttonInstructions.InstructionText }}
    </div>
  }
  @if (buttons) {
    <div class="buttons">
      @if (buttons.CustomPrintButtonShow) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="customReport()"
          [disabled]="!buttons.CustomPrintButtonActive"
        >
          {{ buttons.CustomPrintButtonText }}
        </button>
      }
      @if (buttons.ConsolidatedPrintButtonShow) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="consolidatedReport()"
          [disabled]="!buttons.ConsolidatedPrintButtonActive"
        >
          {{ buttons.ConsolidatedPrintButtonText }}
        </button>
      }
      @if (buttons.ButtonShow) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="submitCase($event, 'Actions', buttons)"
          [disabled]="!buttons.ButtonActive"
        >
          {{ buttons.ButtonText }}
        </button>
      }
      @if (buttons.ButtonShow2) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="submitCase($event, 'Actions', buttons, 2)"
          [disabled]="!buttons.ButtonActive2"
        >
          {{ buttons.ButtonText2 }}
        </button>
      }
      @if (buttons.CMCreateButtonShow) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="submitCaseManagement()"
          [disabled]="!buttons.CMCreateButtonActive || disableCreateCm"
        >
          {{ buttons.CMCreateButtonText }}
        </button>
      }
      @if (buttons.CMLaunchButtonShow || profileCRID) {
        <button
          mat-raised-button
          class="mr-3"
          color="primary"
          (click)="launchCaseManagement()"
          [disabled]="!buttons.CMLaunchButtonActive && !profileCRID"
        >
          {{ buttons.CMLaunchButtonText }}
        </button>
      }
    </div>
  }
</div>

<form #samlForm ngNoForm method="POST">
  <input type="hidden" name="SAMLResponse" />
</form>
