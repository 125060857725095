<div mat-dialog-header>
  <h1>Trade Approval History</h1>
</div>
<mat-dialog-content class="dialog-container row">
  <div class="annuity-exchange-container info-card mb-4">
    <table>
      <thead>
        <tr>
          @for (header of tradeApprovalKey; track header.label) {
            <th>{{ header.label }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (
          tradeApproval of data.tradeDetail.Details.TradeHistoryTable;
          track tradeApproval
        ) {
          <tr>
            @for (header of tradeApprovalKey; track header.label) {
              <td>{{ tradeApproval[header.varName] }}</td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button mat-raised-button (click)="cancel()">Close</button>
</mat-dialog-actions>
