import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGlobalsService } from '../services/app-globals.service';
import { PostTradeComponent } from './post-trade-shell/post-trade.component';
import { PostTradeReportsComponent } from './post-trade-reports/post-trade-reports.component';
import { TradeReviewComponent } from './trade-review/trade-review-shell/trade-review.component';
import { AccountReviewComponent } from './account-review/account-review-shell/account-review.component';
import { AuthService } from '../services/auth-service.service';

const routes: Routes = [
  {
    path: 'post-trade',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: PostTradeComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'PostTrade',
          breadcrumb: 'Trade Review',
        },
      },
      {
        path: 'reports',
        component: PostTradeReportsComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'PostTrade',
          breadcrumb: 'Trade Review',
        },
      },
    ],
  },
  {
    path: 'trade-review',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: TradeReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'TradeReview',
          breadcrumb: 'Trade Review',
        },
      },
      {
        path: ':id',
        component: TradeReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'TradeReview',
          breadcrumb: 'Trade Review',
        },
      },
    ],
  },
  {
    path: 'account-review',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: AccountReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'AccountReview',
          breadcrumb: 'Account Review',
        },
      },
      {
        path: ':id',
        component: AccountReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'AccountReview',
          breadcrumb: 'Account Review',
        },
      },
    ],
  },
  {
    path: 'rep-review',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: TradeReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'RepReview',
          breadcrumb: 'Rep Review',
        },
      },
      {
        path: ':id',
        component: TradeReviewComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'RepReview',
          breadcrumb: 'Rep Review',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostTradeRouting {}
