import {
  throwError as observableThrowError,
  Observable,
  Subject,
  BehaviorSubject,
  of,
  from,
  throwError,
} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpParams,
} from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';
import { RightBridgeApiService } from './right-bridge-api.service';

@Injectable()
export class BeaconServiceV2 {
  private environment;
  private globals = this.ss.get('globals');
  private sessionId: string = this.ss.get('session');
  private baseUrl: string;
  private beaconBaseUrl = 'https://api.an.annuitynexus.com/api/v1/';
  private firm: string;
  private app: string;
  private org: string;
  private beaconUrl =
    'https://api.an.annuitynexus.com/saml/consume?idp_id=aa454b149337b9ce';
  private token: string;
  private annuitySelectedSource = new Subject();

  annuitySelected$ = this.annuitySelectedSource.asObservable();

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService,
    private rbs: RightBridgeApiService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  getCompanies() {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Beacon/GetCompanies`;
    return this.rbs.getRequest(url, reqHeaders).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getProductData(company, date) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const purchDate = new Date(date);
    const url = `${
      this.baseUrl
    }/Beacon/GetProducts?company=${company}&date=${purchDate.toISOString()}`;
    return this.rbs.getRequest(url, reqHeaders).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getProductDetails(key, type, profile, carrier, company) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Beacon/GetProductDetails?product=${key}&productType=${type}&caseId=${profile}&carrierName=${carrier}&company=${company}`;
    return this.rbs.getRequest(url, reqHeaders).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        if (error.ErrorType.toLowerCase() === 'not found') {
          return throwError({ type: 404, message: error.Error });
        }
        return this.errSvc.handleError(error);
      })
    );
  }

  annuityData(ann) {
    this.annuitySelectedSource.next(ann);
  }
}
