import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PostTradeService } from '../../post-trade.service';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-trade-review-dashboard',
  templateUrl: './trade-review-dashboard.component.html',
  styleUrls: ['./trade-review-dashboard.component.scss'],
})
export class TradeReviewDashboardComponent implements OnInit, OnDestroy {
  constructor(
    private route: Router,
    public ptSvc: PostTradeService,
    public cmSvc: CaseManagementService,
    public ss: SessionStorageService
  ) {}

  unsubscribe: Subject<any> = new Subject();
  loading = false;
  currentUser = null;
  pendingRecordsTable = [
    {
      label: 'Red Trades',
      varName: 'NegativeCount',
      count: 0,
      icon: 'remove_circle',
    },
    {
      label: 'Yellow Trades',
      varName: 'WarningCount',
      count: 0,
      icon: 'error',
    },
    {
      label: 'Green Trades',
      varName: 'PositiveCount',
      count: 0,
      icon: 'check_circle',
    },
  ];
  agingCasesHeaders = [
    {
      label: 'Last Updated',
      varName: 'DateLastUpdated',
      dataMask: 'date',
    },
    {
      label: 'Created',
      varName: 'CreatedDate',
      dataMask: 'date',
    },
    {
      label: 'Case ID',
      varName: 'CRID',
    },
    {
      label: 'Rep Code',
      varName: 'SubmittedBy',
    },
    {
      label: 'Rep Name',
      varName: 'SubmittedByName',
    },
    {
      label: 'Case Status',
      varName: 'StatusName',
    },
    {
      label: 'Assigned To',
      varName: 'AssignedTo',
    },
  ];
  agingCases = [];

  ngOnInit(): void {
    this.currentUser = this.ss.get('globals').user.id;
    this.cmSvc
      .getCaseManagementData({
        search: {
          profileValues: {
            StatusName: 'Assigned',
          },
        },
        take: 10,
        sort: {
          DateLastUpdated: 'asc',
        },
      })
      .subscribe(resp => {
        this.agingCases = resp['Data'];
      });

    this.ptSvc.getTradeGridSummary().subscribe(resp => {
      if (resp['results']['data']) {
        this.pendingRecordsTable.map(record => {
          record.count = resp['results']['data'][0][record.varName];
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  viewCase(ev) {
    this.route.navigate([`/case-management/${ev}`]);
  }
}
