import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { AppGlobalsService } from '../services/app-globals.service';
import { TemplatesComponent } from '../account-management/templates/templates.component';
import { ProductGridComponent } from './product-grid/product-grid.component';

const routes: Routes = [
  {
    path: 'iw',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'IW',
        },
      },
      {
        path: 'product-shelf',
        component: ProductGridComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'IW',
        },
      },
      {
        path: 'product-shelf/:program',
        component: ProductGridComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'IW',
        },
      },
      // THE FOLLOWING ARE OLD ROUTES THAT HAVE BEEN
      // REDIRECTED TO THE LANDING PAGE
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'search',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'lookup',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'investment-model/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'program-selection/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'fund-allocations/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'validate/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'advisory/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'help',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'proxy',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IwRouting {}
